$(document).ready(function () {
    $('iframe')[0].width = '100%';
    $('iframe')[0].height = '100%';
    $('iframe')[0].src = $('iframe')[0].src + "&autoplay=0";

    /* go down button */
    $('#scroll-down').click(function (event) {
        $('html, body').animate({
            scrollTop: $('#landing-section').height() + 10
        }, 800);
    });

    /* modal and video*/
    $('#play-video').click(function () {
        $('#full-screen-video-modal').modal('show');
        $('iframe')[0].src = $('iframe')[0].src.replace("autoplay=0", "autoplay=1");
    });

    $('#full-screen-video-modal').on('hidden.bs.modal', function () {
        $('iframe')[0].src = $('iframe')[0].src.replace("autoplay=1", "autoplay=0");
    });
});